import {BooleanNumField} from '@/components/field'
import {Producer, ProducerProduction, User} from '@/types'
import {Typography} from '@mui/material'
import * as React from 'react'
import {Fragment, ReactNode} from 'react'
import {Datagrid, DatagridProps, DateField, EditButton, FunctionField, ReferenceField, TextField} from 'react-admin'

const DatagridActionsColumn = ({label, children}: {
	label: string | ReactNode,
	children: ReactNode
}) => <>{children}</>

export default function DatagridProduction(props: DatagridProps) {
	return (
		<Datagrid
			optimized
			rowClick="edit"
			bulkActionButtons={false}
			{...props}
		>
			<TextField source="id" label="Identifiant"/>
			<FunctionField
				label="Dénomination"
				render={(record: ProducerProduction) =>
					<>
						<ReferenceField source="product" reference="products">
							<TextField source="name" label="Produit d'origine"/>
						</ReferenceField>
						<Typography variant={'subtitle2'}>{record.name || 'Pas de nom personnalisé'}</Typography>
					</>
				}
			/>
			<ReferenceField source="producer" reference="producers" label="Producteur">
				<FunctionField
					label="Producteur"
					render={(record: Producer) =>
						<>
							<Typography variant={'caption'}>{record.name}</Typography>
							<Typography variant={'subtitle2'}>{record.name_enterprise}</Typography>
						</>
					}
				/>
			</ReferenceField>
			<FunctionField
				source="is_local"
				label="Local ?"
				render={(record: User, source: string | undefined) =>
					<BooleanNumField
						label="Publié"
						source={source}
					/>
				}
			/>
			<DateField label="Création" source="created_at"/>
			<DateField label="Mise à jour" source="updated_at"/>
			{props.children}
			<DatagridActionsColumn
				label=""//{<LinkUserDialog/>}
			>
				<FunctionField
					render={(record: ProducerProduction) =>
						<EditButton
							resource="producers"
							record={{id: record.producer}}
							label="Fiche Producteur"
						/>
					}
				/>
				<EditButton
					label="Fiche Production"
				/>
			</DatagridActionsColumn>
		</Datagrid>
	)
}
