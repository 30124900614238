import LinkButton from '@/components/editor/LinkButton'
import MoreButton, {SpecialTag} from '@/components/editor/MoreButton'
import {Box, GlobalStyles, Typography} from '@mui/material'
import Link from '@tiptap/extension-link'

import {
	AlignmentButtons,
	ClearButtons,
	DefaultEditorOptions,
	FormatButtons,
	LevelSelect,
	ListButtons,
	QuoteButtons,
	RichTextInput,
	RichTextInputToolbar
} from 'ra-input-rich-text'
import React, {ReactNode} from 'react'

export const EditorOptions = {
	...DefaultEditorOptions,
	extensions: [
		...DefaultEditorOptions.extensions,
		SpecialTag,
		Link.configure({
			HTMLAttributes: {
				class: 'link'
			},
			openOnClick: false,
			autolink: false,
			validate: href => /^https:\/\//.test(href)
		})
	]
}

export default function DefaultEditor({
										  source = 'description',
										  label = 'Déscription',
										  children,
										  readOnly = false
									  }: {
	source?: string,
	label?: string,
	children?: ReactNode
	readOnly?: boolean
}) {

	// const {
	//     getValues,
	//     setValue,
	//     watch
	// } = useFormContext()
	//
	// const content = watch(source)
	//
	// useEffect(() => {
	//     if (content) {
	//         console.log('content', content)
	//         setValue(source, content.replace(/<div data-more="true">.*?<\/div>/g, '<!--more-->'))
	//     }
	// }, [content])

	return (
		<Box sx={{
			width: '100%'
		}}>
			<GlobalStyles
				styles={{
					'.ProseMirror': {
						minHeight: 200
					}
				}}
			/>
			<Typography variant="h6" gutterBottom>{label}</Typography>
			{children}
			<RichTextInput
				editorOptions={EditorOptions}
				name={source}
				source={source}
				label=" "
				fullWidth
				readOnly={readOnly}
				toolbar={
					<RichTextInputToolbar size="medium">
						<LevelSelect size="medium"/>
						<FormatButtons size="medium"/>
						<AlignmentButtons size="medium"/>
						<ListButtons size="medium"/>
						{/*<LinkButtons size="medium"/>*/}
						<QuoteButtons size="medium"/>
						<ClearButtons size="medium"/>
						{/*<ModalPhotos size="medium" render="toogle"/>*/}
						<LinkButton/>
						<MoreButton/>
					</RichTextInputToolbar>
				}
			/>
		</Box>
	)
}


