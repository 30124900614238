import {SxProps, Typography} from '@mui/material'
import * as React from 'react'
import {memo} from 'react'

import {FieldProps, useRecordContext} from 'react-admin'
import {Commune} from '../../types'

interface Props extends FieldProps<Commune> {
	size?: string;
	sx?: SxProps;
}

const FullNameField = (props: Props) => {
	// const { size } = props;
	const record = useRecordContext<Commune>()
	return record ? (
		<Typography
			variant="body2"
			display="flex"
			flexWrap="nowrap"
			alignItems="center"
			component="div"
			sx={props.sx}
		>
			{record.label}
		</Typography>
	) : null
}

FullNameField.defaultProps = {
	source: 'libelle',
	label: 'resources.terroirs.fields.libelle'
}

export default memo<Props>(FullNameField)
