import FullNameField from '@/components/field/FullNameField'
import DefaultEditor from '@/components/form/DefaultEditor'
import FieldsPresentation from '@/producers/productions/components/fields/Presentation'
import FieldsProducer from '@/producers/productions/components/fields/Producer'
import {ProducerProduction, Product} from '@/types'
import {Box, Grid, Typography} from '@mui/material'
import React, {useEffect} from 'react'
import {
	DeleteButton,
	Edit,
	FunctionField,
	ReferenceField,
	SaveButton,
	SimpleForm,
	TabbedShowLayout,
	Toolbar,
	useEditController,
	useNotify
} from 'react-admin'

export default function ProductionEdit() {

	const {record, isLoading: loading} = useEditController<ProducerProduction>()

	useEffect(() => {
		if (record) {
			document.title = `Production #${record.id} ${record.name}`
		}
	}, [record])

	return (
		<Edit
			mutationMode="pessimistic"
			title={<FullNameField size="32" sx={{margin: '5px 0'}}/>}
		>
			<SimpleForm
				// record={record}
				warnWhenUnsavedChanges
				toolbar={<FormToolbar/>}
				sx={{
					padding: {
						md: '32px',
						xs: '16px'
					}
				}}
			>
				<Typography variant="h4">Modification de la production</Typography>
				<Typography variant="overline" sx={{display: 'block'}}>
					Produit d'origine
				</Typography>
				<ReferenceField source="product" reference="products">
					<FunctionField
						render={(record: Product) =>
							<Typography variant="h6" gutterBottom>{record.name}</Typography>
						}
					/>
				</ReferenceField>
				<Grid container spacing={5} sx={{width: '100%'}}>
					<Grid item md={7}>
						<TabbedShowLayout
							sx={{
								width: '100%'
							}}
						>
							<TabbedShowLayout.Tab label="Présentation">
								<FieldsPresentation/>
								<DefaultEditor/>
							</TabbedShowLayout.Tab>
							{/*<TabbedShowLayout.Tab label="Utilisateurs autorisés" path="users">*/}
							{/*    <Box*/}
							{/*        sx={{*/}
							{/*            display: 'flex',*/}
							{/*            justifyContent: 'flex-end'*/}
							{/*        }}*/}
							{/*    >*/}
							{/*        <LinkUserDialog*/}
							{/*            resourceToUpdate="producers/stores"*/}
							{/*        />*/}
							{/*    </Box>*/}
							{/*    <ReferenceManyField*/}
							{/*        reference="producers/users"*/}
							{/*        target="store_id"*/}
							{/*        pagination={<Pagination/>}*/}
							{/*    >*/}
							{/*        <DatagridUser*/}
							{/*            bulkActionButtons={*/}
							{/*                <LinkedUsersBulkActions/>*/}
							{/*            }*/}
							{/*        />*/}
							{/*    </ReferenceManyField>*/}
							{/*</TabbedShowLayout.Tab>*/}
						</TabbedShowLayout>
					</Grid>
					<Grid item xs={12} md={5}>
						<FieldsProducer/>
					</Grid>
				</Grid>
			</SimpleForm>
		</Edit>
	)
}

const FormToolbar = () => {

	const notify = useNotify()

	return (
		<Toolbar className="fixed-bottom" sx={{display: 'flex', justifyContent: 'space-between'}}>
			<Box sx={{display: 'flex', gap: 5}}>
				<SaveButton alwaysEnable className="save" label="Enregistrer et quitter"/>
				<SaveButton alwaysEnable className="save" label="Enregistrer" type="button" variant="text"
							mutationOptions={{
								onSuccess: (data) => {
									notify('ra.notification.updated', {
										type: 'info',
										messageArgs: {smart_count: 1}
									})
								}
							}}/>
			</Box>
			<DeleteButton/>
		</Toolbar>
	)
}
