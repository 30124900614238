import DefaultEditor from '@/components/form/DefaultEditor'
import FieldsPresentation from '@/producers/productions/components/fields/Presentation'
import FieldsProducer from '@/producers/productions/components/fields/Producer'
import {Box, Grid, Typography} from '@mui/material'
import React, {useEffect} from 'react'
import {Create, DeleteButton, SaveButton, SimpleForm, Toolbar, useNotify, useRedirect} from 'react-admin'

export default function ProducerProductionCreate() {

	useEffect(() => {
		document.title = 'Ajouter une production'
	}, [])

	return (
		<Create
			title={'Ajouter une production'}
		>
			<SimpleForm
				warnWhenUnsavedChanges
				noValidate
				toolbar={<FormToolbar/>}
				sx={{
					padding: {
						md: '32px',
						xs: '16px'
					}
				}}
			>
				<Typography variant="h4" gutterBottom>Création d'une production</Typography>
				<Grid
					container
					spacing={5}
				>
					<Grid item xs={12} md={7}>
						<FieldsPresentation/>
						<DefaultEditor/>
					</Grid>
					<Grid item xs={12} md={5}>
						<FieldsProducer isCreateContext={true}/>
					</Grid>
				</Grid>
			</SimpleForm>
		</Create>
	)
}

const FormToolbar = () => {

	const notify = useNotify()
	const redirect = useRedirect()

	return (
		<Toolbar className="fixed-bottom" sx={{display: 'flex', justifyContent: 'space-between'}}>
			<Box sx={{display: 'flex', gap: 5}}>
				<SaveButton
					className="save"
					label="Enregistrer"
					mutationOptions={{
						onSuccess: event => {
							// @todo redirect liste
							notify('ra.notification.created', {
								type: 'info',
								messageArgs: {smart_count: 1}
							})
							// redirect('/users/admins', 'products')
						}
					}}
				/>
			</Box>
			<DeleteButton/>
		</Toolbar>
	)
}
