import ProducerProductionCreate from '@/producers/productions/create'
import ProductionEdit from '@/producers/productions/edit'
import SoupKitchenOutlinedIcon from '@mui/icons-material/SoupKitchenOutlined'

export default {
	icon: SoupKitchenOutlinedIcon,
	edit: ProductionEdit,
	create: ProducerProductionCreate
	// list: ProducerStoreList,
}
