import {FilterListCustom} from '@/components/filter/FilterListCustom'
import FilterTags from '@/components/filter/FilterTags'
import {BookmarkIcon} from '@heroicons/react/24/outline'
import CategoryIcon from '@mui/icons-material/Category'
import ClearIcon from '@mui/icons-material/Clear'
import FilterListIcon from '@mui/icons-material/FilterList'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import {Card, CardContent} from '@mui/material'
import {useEffect, useState} from 'react'
import {FilterList, FilterListItem, SavedQueriesList, useListFilterContext} from 'react-admin'

const VIEW_FILTERS_FALSE = {
	filterPublished: false,
	filterType: false,
	filterCategorie: false,
	filterTags: false
}

const Aside = () => {
	const [types, setTypes] = useState([])
	const [categories, setCategories] = useState([])
	const [viewFilters, setViewFilters] = useState<PropsViewFiltersProduct>(VIEW_FILTERS_FALSE)
	const [loading, setLoading] = useState(true)
	const {filterValues, setFilters} = useListFilterContext()

	interface PropsViewFiltersProduct {
		filterPublished: boolean,
		filterType: boolean,
		filterCategorie: boolean,
		filterTags: boolean,
	}

	// ###################
	// CHARGEMENT DES DONNEES
	useEffect(() => {
		const getOptions = async () => {

			/**
			 * Types
			 */
			fetch(process.env.REACT_APP_DB_LOPT + '/products/types')
				.then(res => res.json())
				.then((res) => {
					setTypes(res.sort((a: any, b: any) => a.name.localeCompare(b.name)))
				})
			/**
			 * Categories
			 */
			fetch(process.env.REACT_APP_DB_LOPT + '/products/categories?_end=null')
				.then(res => res.json())
				.then((res) => {
					setCategories(res.sort((a: any, b: any) => a.name.localeCompare(b.name)))
				})

			setLoading(false)
		}

		if (loading) {
			getOptions()
		}

	}, [])

	const resetFilters = () => {
		setViewFilters(VIEW_FILTERS_FALSE)
		setFilters([], false)
	}
	console.log(filterValues)
	return (
		<Card
			sx={{
				display: {
					xs: 'none',
					md: 'block'
				},
				order: -1,
				flex: '0 0 15em',
				mr: 2,
				mt: 8,
				alignSelf: 'flex-start'
			}}
		>
			<CardContent sx={{pt: 1}}>
				{/*<FilterLiveSearch />*/}
				<SavedQueriesList icon={<BookmarkIcon/>}/>
				<FilterListCustom
					label="Publié"
					icon={<PublishedWithChangesIcon/>}
					active={viewFilters.filterPublished || filterValues.isPublished !== undefined}
					onClick={() =>
						setViewFilters({
							...viewFilters,
							filterPublished: !viewFilters.filterPublished
						})
					}
				>
					{
						<>
							<FilterListItem
								key="published1"
								label="Oui"
								value={{isPublished: 1}}
							/>
							<FilterListItem
								key="published0"
								label="Non"
								value={{isPublished: 0}}
							/>
						</>
					}
				</FilterListCustom>

				<FilterList
					label="Autres"
					icon={<FilterListIcon/>}
				>
					<FilterListItem
						label="Est une contribution ?"
						value={{contribution: 1}}
					/>
					<FilterListItem
						label="Déclinaisons"
						value={{parent: true}}
					/>
					{types && types.map((type: any) => (
						<FilterListItem
							key={'type' + type.id}
							label={String(type.name)}
							value={{type: type.id}}
						/>
					))
					}
				</FilterList>

				{/*<FilterListCustom*/}
				{/*    label="Type"*/}
				{/*    icon={<FilterListIcon/>}*/}
				{/*    active={viewFilters.filterType}*/}
				{/*    onClick={() =>*/}
				{/*        setViewFilters({*/}
				{/*            ...viewFilters,*/}
				{/*            filterType: !viewFilters.filterType*/}
				{/*        })*/}
				{/*    }*/}
				{/*>*/}
				{/*    {*/}
				{/*        <>*/}
				{/*            {types && types.map((type: any) => (*/}
				{/*                <FilterListItem*/}
				{/*                    key={'type' + type.id}*/}
				{/*                    label={String(type.name)}*/}
				{/*                    value={{type: type.id}}*/}
				{/*                />*/}
				{/*            ))*/}
				{/*            }*/}
				{/*        </>*/}
				{/*    }*/}
				{/*</FilterListCustom>*/}

				<FilterListCustom
					label="Catégorie"
					icon={<CategoryIcon/>}
					active={viewFilters.filterCategorie}
					onClick={() =>
						setViewFilters({
							...viewFilters,
							filterCategorie: !viewFilters.filterCategorie
						})
					}
				>
					{
						<>
							<FilterListItem
								key="category0"
								label="Non classé"
								value={{category: 0}}
							/>
							{categories && categories.filter((data: any) => data.LIBELLE != 'Non catégorisé').map((categorie: any) => (
								<FilterListItem
									key={'categorie' + categorie.id}
									label={String(categorie.name)}
									value={{category: categorie.id}}
								/>
							))
							}
						</>
					}
				</FilterListCustom>

				<FilterTags
					active={viewFilters.filterTags}
					onClick={() =>
						setViewFilters({
							...viewFilters,
							filterTags: !viewFilters.filterTags
						})
					}
				/>

				<FilterListCustom
					label="Réinitialiser"
					icon={<ClearIcon/>}
					active={true}
					toggle={false}
					sx={{...styles.filter, '>div span': {fontSize: '10px'}}}
					onClick={resetFilters}
				/>
			</CardContent>
		</Card>
	)
}

export default Aside

export const styles = {
	filter: {
		'>div:first-child': {
			justifyContent: 'space-between',
			padding: '0',
			'&:hover': {
				cursor: 'pointer',
				backgroundColor: 'rgba(0, 0, 0, 0.04)',
				borderRadius: '6px',
				color: '#4f3cc9'
			}
		},
		'ul > li': {
			'&:hover': {
				cursor: 'pointer',
				backgroundColor: 'rgba(0, 0, 0, 0.04)',
				borderRadius: '6px',
				color: '#4f3cc9'
			}
		}
	},
	buttons: {
		display: 'flex',
		flexDirection: 'column',
		defaultBtn: {
			height: '36px'
		},
		btn1: {
			width: '70%'
		},
		btn2: {
			width: '30%'
		}
	}
}

export const transitions = (active: boolean = false, delay: string) => {
	return (
		{
			overflow: 'hidden',
			maxHeight: active ? '1000px' : 0,
			transition: 'max-height ' + delay + ' ease-in-out'
		}
	)
}
