import {Producer, ProducerProduction, ProducerStore} from '@/types'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import {Alert, AlertTitle, Box, Button, Card, CardContent, Typography} from '@mui/material'
import React from 'react'
import {
	AutocompleteArrayInput,
	AutocompleteInput,
	BooleanInput,
	FunctionField,
	ReferenceArrayInput,
	ReferenceField,
	ReferenceInput,
	required,
	useCreatePath,
	useRecordContext
} from 'react-admin'

export default function FieldsProducer({
										   isCreateContext = false
									   }) {

	const record = useRecordContext<ProducerProduction>()
	const createPath = useCreatePath()

	return (
		<Card variant={'outlined'} sx={{
			width: '100%'
		}}>
			<CardContent>
				<Box mb={4}>
					<Typography variant="overline" gutterBottom>Producteur</Typography>
					{!isCreateContext &&
                        <ReferenceField source="producer" reference="producers">
                            <FunctionField
                                label="Libellé"
                                render={(record: Producer) =>
									<>
										{record.name &&
                                            <Typography variant="h6" gutterBottom>{record.name}</Typography>
										}
										{record.name_enterprise &&
                                            <Typography variant="h5" gutterBottom>{record.name_enterprise}</Typography>
										}
									</>
								}
                            />
                            <FunctionField
                                label="Libellé"
                                render={(record: Producer) =>
									<Button
										href={createPath({
											resource: 'producers',
											type: 'edit',
											id: record.id
										})}
										variant="outlined"
										style={{textDecoration: 'none'}}
										target={'_blank'}
										startIcon={<ArrowOutwardIcon/>}
									>
										Voir la fiche entreprise
									</Button>
								}
                            />
                        </ReferenceField>
					}
					{isCreateContext &&
                        <ReferenceInput
                            name="producer"
                            source="producer"
                            reference="producers"
                        >
                            <AutocompleteInput
                                label="Entreprise"
                                name="producer"
                                blurOnSelect={false}
                                optionText={(option: Producer) => `${option.name_enterprise} -- ${option.name}`}
                                optionValue="id"
                                debounce={100}
                                validate={[required()]}
                                sx={{
									mt: 2
								}}
                            />
                        </ReferenceInput>
					}
				</Box>
				<BooleanInput
					label="Produit localement par le producteur"
					name="is_local"
					source="is_local"
				/>
				<Typography variant={'h6'} gutterBottom>Disponibilité</Typography>
				<Alert severity="info" style={{marginBottom: 20}}>
					<AlertTitle>Gestion de la disponibilité</AlertTitle>
					Si aucun établissement n'est renseigné le produit sera marqué comme disponible partout.
				</Alert>
				<ReferenceArrayInput
					name="availability"
					reference="producers/stores"
					source="availability"
					filter={{producer_id: record.producer}}
				>
					<AutocompleteArrayInput
						label="Disponibilités dans les établissements"
						name="availability"
						optionText={(option: ProducerStore) => `${option.name}, ${option.commune_zipcode}`}
						multiple={true}
						optionValue="id"
						debounce={250}
					/>
				</ReferenceArrayInput>
			</CardContent>
		</Card>
	)
}
