import {Media} from '@/types'
import {IconButton, ImageList, ImageListItem, ImageListItemBar, Typography} from '@mui/material'
import React from 'react'
import {ReferenceArrayField, useCreatePath, useListContext} from 'react-admin'

function ImagesList() {

	const {data} = useListContext()
	const createPath = useCreatePath()

	return (
		<ImageList cols={4} variant="woven">
			{data.map((record: Media) => (
				<ImageListItem
					component="a"
					href={createPath({
						resource: '#/media',
						id: record.id,
						type: 'edit'
					})}
					key={record.id}
					sx={{
						aspectRatio: '16/9'
					}}
				>
					<img
						src={`${String(process.env.REACT_APP_IMAGE_URL)}${record.path}`}
						srcSet={`${String(process.env.REACT_APP_IMAGE_URL)}${record.path}`}
						alt={record.description}
						className="list-item-img"
					/>
					<ImageListItemBar
						title={record.description}
						className="list-item-bar"
						actionIcon={
							<IconButton
								sx={{color: 'rgba(255, 255, 255, 0.54)'}}
								aria-label={`Information about ${record.description}`}
							>
							</IconButton>
						}
					/>
				</ImageListItem>
			))}
		</ImageList>
	)
}

export default function ImagesListInput({
											name = 'images'
										}: {
	name?: string
}) {

	return (
		<>
			<Typography variant="h6">Images</Typography>
			<Typography variant="caption" gutterBottom>(Pas encore d'édition pour les images)</Typography>
			<ReferenceArrayField reference="media" source={name}>
				<ImagesList/>
			</ReferenceArrayField>
			{/*<ReferenceArrayInput*/}
			{/*	name={name}*/}
			{/*	reference="media"*/}
			{/*	source={name}*/}
			{/*>*/}
			{/*	<ArrayInput source={name} resource="media">*/}
			{/*		<SimpleFormIterator inline>*/}
			{/*			<TextInput source="path" helperText={false}/>*/}
			{/*		</SimpleFormIterator>*/}
			{/*	</ArrayInput>*/}
			{/*</ReferenceArrayInput>*/}
			{/*<ArrayInput source={name} resource="media">*/}
			{/*	<SimpleFormIterator inline>*/}
			{/*		<TextInput source="path" helperText={false}/>*/}
			{/*	</SimpleFormIterator>*/}
			{/*</ArrayInput>*/}
		</>
	)
}
