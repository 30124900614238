import ImagesListInput from '@/components/form/ImagesListInput'
import {ProducerProduction, Product} from '@/types'
import {Box, Typography} from '@mui/material'
import React from 'react'
import {
	AutocompleteInput,
	Link,
	ReferenceInput,
	required,
	TextInput,
	useCreatePath,
	useRecordContext
} from 'react-admin'

export default function FieldsPresentation() {

	const record = useRecordContext<ProducerProduction>()
	const createPath = useCreatePath()

	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column'
		}}>
			<Typography variant="h6" gutterBottom>Présentation</Typography>
			<TextInput
				label="Nom"
				name="name"
				source="name"
				fullWidth
			/>
			<ReferenceInput
				name="product"
				source="product"
				reference="products"
				sort={{field: 'name', order: 'ASC'}}
			>
				<AutocompleteInput
					label="Produit associé"
					name="product"
					optionText={(option: Product) => (
						<div>
							<Typography variant={'caption'}>#{option.id}</Typography>
							<Typography
								variant={'subtitle1'}>{option.name}{option.name_alt ? ` -- ${option.name_alt}` : ''}</Typography>
						</div>
					)}
					optionValue="id"
					debounce={100}
					inputText={(option: Product) => `#${option.id} ${option.name}`}
					validate={[required()]}
					helperText={
						<>
							{record?.product && <Link
                                to={createPath({
									resource: 'products',
									type: 'edit',
									id: record!.product
								})}
                            >
                                Modifier la fiche produit
                            </Link>}
						</>
					}
				/>
			</ReferenceInput>
			<ImagesListInput/>
			{/*<ReferenceArrayInput*/}
			{/*	name="images"*/}
			{/*	reference="media"*/}
			{/*	source="images"*/}
			{/*>*/}
			{/*	<FileInput source="attachments">*/}
			{/*		<FileField source="path" title="title"/>*/}
			{/*	</FileInput>*/}
			{/*</ReferenceArrayInput>*/}
			{/*<ReferenceArrayInput*/}
			{/*	name="images"*/}
			{/*	reference="media"*/}
			{/*	source="images"*/}
			{/*>*/}
			{/*	<AutocompleteArrayInput*/}
			{/*		label="Disponibilités dans les établissements"*/}
			{/*		name="images"*/}
			{/*		optionText={(option: ProducerStore) => `${option.name}, ${option.commune_zipcode}`}*/}
			{/*		multiple={true}*/}
			{/*		optionValue="id"*/}
			{/*		debounce={250}*/}
			{/*	/>*/}
			{/*</ReferenceArrayInput>*/}
		</Box>
	)
}
