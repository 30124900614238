import FullNameField from '@/components/field/FullNameField'
import BeforeSave from '@/layout/BeforeSave'
import ModalPhotos from '@/layout/ModalPhotos'
import ModalViewCode from '@/layout/ModalViewCode'
import TagCreate from '@/tags/ModalTagCreate'
import {Product} from '@/types'
import {Alert, AlertTitle, Box, Grid, Typography} from '@mui/material'
import {
	AlignmentButtons,
	ClearButtons,
	FormatButtons,
	LevelSelect,
	LinkButtons,
	ListButtons,
	QuoteButtons,
	RichTextInput,
	RichTextInputToolbar
} from 'ra-input-rich-text'
import React, {useEffect, useState} from 'react'
import {
	AutocompleteArrayInput,
	AutocompleteInput,
	CheckboxGroupInput,
	Create,
	ReferenceArrayInput,
	ReferenceInput,
	required,
	SaveButton,
	SelectInput,
	SimpleForm,
	TextInput,
	Toolbar,
	useNotify,
	useRedirect,
	useTranslate
} from 'react-admin'
import './style.css'

export default function ProductCreate() {

	const translate = useTranslate()

	const loading = false
	const [optionsT, setOptionsT] = useState<any>([])
	const [availableCategories, setAvailableCategories] = useState<any>([])

	useEffect(() => {
		document.title = 'Nouveau produit'
	}, [])

	useEffect(() => {

		const getOptions = async () => {

			// @todo Utiliser les references field + ressource (comme pour les tags)
			/**
			 * Types
			 */
			fetch(process.env.REACT_APP_DB_LOPT + '/products/types')
				.then(res => res.json())
				.then((res) => {
					// console.log(res)
					setOptionsT(res.sort((a: any, b: any) => a.name.localeCompare(b.name)))
				})
			/**
			 * Categories
			 */
			fetch(process.env.REACT_APP_DB_LOPT + '/products/categories?_end=null')
				.then(res => res.json())
				.then((res) => {
					// console.log(res)
					setAvailableCategories(res.sort((a: any, b: any) => a.name.localeCompare(b.name)))
				})
		}

		getOptions()

	}, [])

	return (
		<Create title={<ProductTitle/>}>
			<SimpleForm
				warnWhenUnsavedChanges
				noValidate
				toolbar={<FormToolbar/>}
				sx={{
					padding: {
						md: '32px',
						xs: '16px'
					}
				}}
			>
				<Grid container display={{xs: 'block', md: 'flex'}} sx={{gap: '30px', justifyContent: 'space-between'}}>
					<Grid item xs={12} md={5}>
						<Typography variant="h6" gutterBottom>
							Informations
						</Typography>
						<Box sx={{display: 'flex', gap: '20px'}}>
							<TextInput
								disabled
								source="id"
								id="identifiant"
								required
								fullWidth
							/>
							<SelectInput
								isLoading={loading}
								className="selectInput"
								source="is_published"
								format={v => String(v)}
								optionValue="id"
								optionText="LIBELLE"
								label="Publié ?"
								defaultValue={true}
								choices={[
									{id: false, LIBELLE: 'Non'},
									{id: true, LIBELLE: 'Oui'}
								]}
								emptyValue="empty"
								fullWidth
								required
								validate={req}
							/>
						</Box>

						<TextInput
							source="name"
							fullWidth
							validate={req}
						/>

						<TextInput
							source="name_alt"
							fullWidth
						/>

						<TextInput
							source="article"
							fullWidth
						/>

						<Typography variant="h6" gutterBottom>
							Origine
						</Typography>
						<Alert severity="info" style={{width: '100%', marginBottom: 16}}>
							<AlertTitle>Gestion du produit parent</AlertTitle>
							Un produit/spécialité a un parent lorsque son origine découle d'un autre
							produit (exemple : La <u>Moutarde douce au Riesling</u> aura comme parent le
							produit <u>Moutarde douce</u>)
						</Alert>
						<ReferenceInput
							name="parent"
							reference="products"
							source="parent"
							sort={{field: 'name', order: 'ASC'}}
						>
							<AutocompleteInput
								name="parent"
								label={'Produit parent'}
								helperText="Est-ce que ce produit est une déclinaison d'un autre produit ?"
								optionText={(option: Product) => (
									<div>
										<Typography variant={'caption'}>#{option.id}</Typography>
										<Typography
											variant={'subtitle1'}>{option.name}{option.name_alt ? ` -- ${option.name_alt}` : ''}</Typography>
									</div>
								)}
								inputText={(option: Product) => `#${option.id} ${option.name}`}
								optionValue="id"
								debounce={100}
							/>
						</ReferenceInput>
						<SelectInput
							className="selectInput"
							choices={optionsT}
							isLoading={loading}
							source="type"
							optionText="name"
							optionValue="id"
							emptyValue="empty"
							label="Type"
							fullWidth
							validate={req}
						/>
						<SelectInput
							className="selectInput"
							choices={availableCategories}
							isLoading={loading}
							source="category"
							optionText="name"
							optionValue="id"
							emptyValue="empty"
							label="Catégorie"
							fullWidth
							validate={req}
						/>

						<SelectInput
							className="selectInput"
							choices={[
								{id: false, label: 'Non'},
								{id: true, label: 'Oui'}
							]}
							isLoading={loading}
							source="is_registered_trademark"
							optionText="label"
							optionValue="id"
							emptyValue="empty"
							helperText={false}
							label="Marque déposée"
							fullWidth
						/>

						<CheckboxGroupInput
							label="Label(s) de qualité"
							source="labels"
							helperText={false}
							choices={[
								{id: 'is_aoc', name: 'AOC'},
								{id: 'is_igp', name: 'IGP'}
							]}
						/>

						<ReferenceArrayInput
							name="tags"
							label="Tags"
							reference="tags"
							source="tags"
						>
							<AutocompleteArrayInput
								blurOnSelect={false}
								name="tags"
								optionText="name"
								multiple={true}
								optionValue="id"
								debounce={250}
								create={<TagCreate defaultTypeName="Produit"/>}
							/>
						</ReferenceArrayInput>

						<Box sx={{display: 'flex'}}>
							<TextInput
								type="text"
								source="link_external"
								fullWidth
							/>
							{/*{record && record.link_external &&*/}
							{/*    <Link*/}
							{/*        sx={{*/}
							{/*            alignSelf: 'center',*/}
							{/*            padding: "0 15px",*/}
							{/*            height: "40px",*/}
							{/*            pointerEvents: record.link_external ? "inherit" : "none",*/}
							{/*            cursor: record.link_external ? "pointer" : "not-allowed",*/}
							{/*        }}*/}
							{/*        target="_blank"*/}
							{/*        href={record.link_external}*/}
							{/*    >*/}
							{/*        <LaunchIcon color={record.link_external ? "primary" : "disabled"}/>*/}
							{/*    </Link>*/}
							{/*}*/}
						</Box>

						<TextInput
							source="sources"
							fullWidth
						/>

						<TextInput
							source="created_at"
							disabled={true}
							fullWidth
						/>

						<TextInput
							source="updated_at"
							disabled={true}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Grid>
							<Typography variant="h6" gutterBottom>
								Identité visuelle
							</Typography>
							<BeforeSave/>
						</Grid>
						<Box mt="4em"/>
						<Grid>
							<Typography variant="h6" gutterBottom>
								{translate(
									'resources.products.fieldGroups.terroirslies'
								)}
							</Typography>
							<BeforeSave/>
						</Grid>
						<Box mt="4em"/>
						<Grid>
							<Typography variant="h6" gutterBottom>
								{translate(
									'resources.products.fieldGroups.communesliees'
								)}
							</Typography>
							<BeforeSave/>
						</Grid>
					</Grid>
				</Grid>
				<Box mt="3em"/>
				<Typography variant="h6" gutterBottom>Description</Typography>
				<RichTextInput
					source="description"
					label=" "
					fullWidth
					toolbar={
						<RichTextInputToolbar size="large">
							<LevelSelect size="large"/>
							<FormatButtons size="large"/>
							<AlignmentButtons size="large"/>
							<ListButtons size="large"/>
							<LinkButtons size="large"/>
							<QuoteButtons size="large"/>
							<ClearButtons size="large"/>
							<ModalPhotos render="toogle"/>
							<ModalViewCode/>
						</RichTextInputToolbar>
					}
				/>
			</SimpleForm>
		</Create>
	)
};

const req = [required()]

const ProductTitle = () => <FullNameField size="32" sx={{margin: '5px 0'}}/>

const FormToolbar = () => {

	const redirect = useRedirect()
	const notify = useNotify()

	return (
		<Toolbar className="fixed-bottom" sx={{display: 'flex', justifyContent: 'space-between'}}>
			<Box sx={{display: 'flex', gap: 5}}>
				<SaveButton
					className="save"
					label="Enregistrer et quitter"
					mutationOptions={{
						onSuccess: event => {
							// @todo redirect liste
							notify('ra.notification.created', {
								type: 'info',
								messageArgs: {smart_count: 1}
							})
							redirect('/products', 'products')
						}
					}}
				/>
				<SaveButton
					className="save"
					label="Enregistrer"
					type="button"
					variant="text"
				/>
			</Box>
		</Toolbar>
	)
}
