import {baseFilters} from '@/components/filter/base'
import Aside from '@/products/Aside'
import DatagridProducts from '@/products/components/datagrid'
import {Theme, useMediaQuery} from '@mui/material'
import * as React from 'react'
import {Fragment, useEffect} from 'react'
import {CreateButton, FilterButton, FilterForm, List, Pagination, SimpleList, TextInput, TopToolbar} from 'react-admin'

const postFilters = [
	// <SearchInput source="q" alwaysOn/>,
	<TextInput label="Nom du produit" source="name" defaultValue=""/>
]

const ListToolbar = () => (
	<TopToolbar>
		<FilterForm filters={postFilters}/>
		<FilterButton filters={postFilters}/>
		<CreateButton/>
	</TopToolbar>
)

export default function ProductList() {
	return (
		<List
			sort={{field: 'id', order: 'ASC'}}
			perPage={25}
			exporter={false}
			aside={<Aside/>}
			filters={baseFilters}
			// actions={<ListToolbar/>}
		>
			<TableList/>
		</List>
	)
}

const TableList = () => {

	useEffect((): any => {
		document.title = 'Produits'
	}, [])

	const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

	return (
		<Fragment>
			<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]}/>
			{isSmall ?
				<SimpleList
					primaryText={record => record.name}
					// secondaryText={record => `${record.categoryName}`}
					secondaryText={record => `${record.categoryName}, ${record.typeName}`}
					// tertiaryText={record => new Date(record.published_at).toLocaleDateString()}
				/>
				: <DatagridProducts/>
			}
		</Fragment>
	)
}
