import {Box, Typography} from '@mui/material'
import React from 'react'
import {BooleanInput, TextInput} from 'react-admin'

export default function FieldsPublication() {
	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column'
		}}>
			<Typography variant="h6" gutterBottom>Présence sur le site</Typography>
			<Box sx={{display: 'flex', gap: '20px'}}>
				<TextInput
					disabled
					source="id"
					name="id"
					id="identifiant"
					required
					fullWidth
				/>
			</Box>
			<BooleanInput
				label="Publier l'établissement"
				name="is_published"
				source="is_published"
			/>
		</Box>
	)
}
