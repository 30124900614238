import DatagridUser from '@/producers/users/components/listFields'
import {Alert, AlertTitle} from '@mui/material'
import * as React from 'react'
import {Fragment, useEffect} from 'react'
import {List, Pagination, SearchInput, useListContext} from 'react-admin'

export default function ProducerUsersList() {
	return (
		<>
			<Alert severity="info" style={{width: '100%', marginTop: 20}}>
				<AlertTitle>Type d'utilisateur Producteur</AlertTitle>
				Ce tableau contient les utilisateurs de type <strong>producteur uniquement</strong>
			</Alert>
			<List
				sort={{field: 'id', order: 'DESC'}}
				perPage={25}
				filters={[<SearchInput name="q" source="q" alwaysOn/>]}
				exporter={false}
			>
				<TableList/>
			</List>
		</>
	)
}

const TableList = () => {

	useEffect((): any => {
		document.title = 'Utilisateurs | Producteurs'
	}, [])


	const {data, isLoading, total} = useListContext()

	useEffect(() => {
		if (!isLoading && data) {
			console.log('React Admin List Context Data:', data)
			console.log('Total Records:', total)
		}
	}, [data, isLoading, total])


	return (
		<>
			<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]}/>
			<DatagridUser
				optimized
				rowClick="edit"
			/>
		</>
	)
}
